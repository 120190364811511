import React from 'react';
import Layout from '../component/layout';
import AboutUsHomeComponent from '../component/AboutUs/aboutUsHomeComponent';
import AboutUsBodyComponent from '../component/AboutUs/aboutUsBodyComponent';
import FooterComponent from '../component/footerComponent';
import AboutUsTeamSwiper from './aboutUsTeamSwiper';
import { graphql } from 'gatsby';
const AboutUs = props => {
  const {
    data: {
      wpPage: {
        seo,
        aboutUsHomeSection: {
          aboutUsHomeDescription,
          aboutUsHomeSolutionTextOne,
          aboutUsHomeSolutionTextThree,
          aboutUsHomeSolutionTextTwo,
          aboutUsHomeSolutionTitle,
          aboutUsHomeTitle,
          aboutUsHomeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: aboutUsHomeImage },
            },
          },
        },
        careerPositionSection: {
          careerPositionTitle,
          careerPositionDescription,
          careerPositionFornend,
          careerPositionBackend,
          careerPositionDevops,
          careerPositionDesigner,
          careerPositionQa,
          careerPositionProjectManagement,
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <div style={{ backgroundColor: '#fff' }}>
        <AboutUsHomeComponent
          aboutUsHomeTitle={aboutUsHomeTitle}
          aboutUsHomeDescription={aboutUsHomeDescription}
          aboutUsHomeBodyTitle={aboutUsHomeSolutionTitle}
          aboutUsHomeBodyText1={aboutUsHomeSolutionTextOne}
          aboutUsHomeBodyText2={aboutUsHomeSolutionTextTwo}
          aboutUsHomeBodyText3={aboutUsHomeSolutionTextThree}
          aboutUsHomeImage={aboutUsHomeImage}
        />
        <AboutUsBodyComponent
          aboutUsBodyCardColumn1Title={careerPositionTitle}
          aboutUsBodyCardColumn1Description={careerPositionDescription}
          aboutUsBodyCardColumn2Title={careerPositionFornend}
          aboutUsBodyCardColumn2Description={careerPositionBackend}
          aboutUsBodyDescriptionOne={careerPositionDevops}
          aboutUsBodyDescriptionTwo={careerPositionDesigner}
          aboutUsBodyDescriptionThree={careerPositionQa}
          videoSwiperTitle={careerPositionProjectManagement}
        />
        <AboutUsTeamSwiper />

        <FooterComponent />
      </div>
    </Layout>
  );
};

export default AboutUs;
export const query = graphql`
  {
    wpPage(slug: { eq: "about-us" }) {
      seo {
        title
        metaDesc
      }
      aboutUsHomeSection {
        aboutUsHomeDescription
        aboutUsHomeSolutionTextOne
        aboutUsHomeSolutionTextThree
        aboutUsHomeSolutionTextTwo
        aboutUsHomeSolutionTitle
        aboutUsHomeTitle
        aboutUsHomeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
      }
    }
  }
`;
