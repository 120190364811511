import { Link } from 'gatsby';
import React from 'react';

import ParagraphIcon from '../../../static/assets/03. Features/paragraphIcon.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

// This file is used at : [ aboutUs.js ]

const AboutUsHomeComponent = props => {
  const {
    aboutUsHomeTitle,
    aboutUsHomeDescription,
    aboutUsHomeBodyTitle,
    aboutUsHomeBodyText1,
    aboutUsHomeBodyText2,
    aboutUsHomeBodyText3,
    aboutUsHomeImage,
  } = props;

  return (
    <div className='aboutus-home-section'>
      <div className='aboutus-home-section-container full-container'>
        <div className='row align-items-center'>
          <div className='col-xl-6 order-2 order-xl-1'>
            <div className='aboutus-home-section-container-text'>
              <p
                className='aboutus-home-section-container-text-title'
                dangerouslySetInnerHTML={{ __html: aboutUsHomeTitle }}
              />
              <p
                className='aboutus-home-section-container-text-description'
                dangerouslySetInnerHTML={{ __html: aboutUsHomeDescription }}
              />

              <Link
                to='/contact'
                className='aboutus-home-section-container-text-link'
              >
                Meet Motomtech
              </Link>
            </div>
          </div>
          <div className='aboutus-home-section-container-image order-1 order-xl-2 col-xl-6 d-flex justify-content-xl-start justify-content-center justify-content-xxl-center'>
            <div className='image-shadow' />
            <div className='gradient-1' />
            <div className='gradient-2' />
            <GatsbyImage
              image={aboutUsHomeImage}
              className='aboutus-home-section-container-image-img'
              alt='About us image'
            />
          </div>
        </div>
        <div className='aboutus-home-section-container-description'>
          <p
            className='aboutus-home-section-container-description-title'
            dangerouslySetInnerHTML={{ __html: aboutUsHomeBodyTitle }}
          />

          <div className='aboutus-home-section-container-description-paragraphs'>
            <div className='aboutus-home-section-container-description-paragraphs-paragraph paragraph-1'>
              <div className='d-flex'>
                <div className='aboutus-home-section-container-description-paragraphs-paragraph-icon'>
                  <ParagraphIcon />
                </div>
                <p
                  className='aboutus-home-section-container-description-paragraphs-paragraph-text'
                  dangerouslySetInnerHTML={{ __html: aboutUsHomeBodyText1 }}
                />
              </div>
            </div>

            <div className='aboutus-home-section-container-description-paragraphs-paragraph paragraph-2'>
              <div className='d-flex'>
                <div className='aboutus-home-section-container-description-paragraphs-paragraph-icon'>
                  <ParagraphIcon />
                </div>
                <p
                  className='aboutus-home-section-container-description-paragraphs-paragraph-text'
                  dangerouslySetInnerHTML={{ __html: aboutUsHomeBodyText2 }}
                />
              </div>
            </div>

            <div className='aboutus-home-section-container-description-paragraphs-paragraph paragraph-3'>
              <div className='d-flex'>
                <div className='aboutus-home-section-container-description-paragraphs-paragraph-icon'>
                  <ParagraphIcon />
                </div>
                <p
                  className='aboutus-home-section-container-description-paragraphs-paragraph-text'
                  dangerouslySetInnerHTML={{ __html: aboutUsHomeBodyText3 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHomeComponent;
