import React from 'react';
import IconSvg from '../../../static/assets/homeSquare.svg';
import Swiper from '../swiper';

// This file is used at : [ aboutUs.js ]

const AboutUsBodyComponent = props => {
  const {
    aboutUsBodyCardColumn1Title,
    aboutUsBodyCardColumn1Description,
    aboutUsBodyCardColumn2Title,
    aboutUsBodyCardColumn2Description,
    aboutUsBodyDescriptionOne,
    aboutUsBodyDescriptionTwo,
    aboutUsBodyDescriptionThree,
  } = props;

  return (
    <>
      <div className='aboutus-body-section-container'>
        <div className='aboutus-body-section-card'>
          <div className='aboutus-body-section-card-column'>
            <p
              className='aboutus-body-section-card-column-title'
              dangerouslySetInnerHTML={{ __html: aboutUsBodyCardColumn1Title }}
            />
            <p
              className='aboutus-body-section-card-column-description'
              dangerouslySetInnerHTML={{
                __html: aboutUsBodyCardColumn1Description,
              }}
            />
          </div>
          <div className='divider' />
          <div className='aboutus-body-section-card-column'>
            <p
              className='aboutus-body-section-card-column-title'
              dangerouslySetInnerHTML={{ __html: aboutUsBodyCardColumn2Title }}
            />
            <p
              className='aboutus-body-section-card-column-description'
              dangerouslySetInnerHTML={{
                __html: aboutUsBodyCardColumn2Description,
              }}
            />
          </div>
        </div>
      </div>
      <div className='aboutus-body-section'>
        <div className=''>
          <div className='aboutus-body-section-wrapper'>
            <div className='aboutus-body-section-wrapper-container d-flex flex-column '>
              <div className='aboutus-body-section-wrapper-icon'>
                <IconSvg />
              </div>
              <div className='aboutus-body-section-wrapper-col'>
                <p className='aboutus-body-section-wrapper-col-title'>
                  We create innovative <span>digital solutions</span>
                </p>
                <p
                  className='aboutus-body-section-wrapper-col-description'
                  dangerouslySetInnerHTML={{
                    __html: aboutUsBodyDescriptionOne,
                  }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <div className='aboutus-body-section-wrapper-container '>
                <div className='aboutus-body-section-wrapper-icon'>
                  <IconSvg />
                </div>
                <div className='aboutus-body-section-wrapper-col'>
                  <p className='aboutus-body-section-wrapper-col-title'>
                    We make the <span>impossible possible</span>
                  </p>
                  <p
                    className='aboutus-body-section-wrapper-col-description'
                    dangerouslySetInnerHTML={{
                      __html: aboutUsBodyDescriptionTwo,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='aboutus-body-section-wrapper-container '>
              <div className='aboutus-body-section-wrapper-icon'>
                <IconSvg />
              </div>
              <div className='aboutus-body-section-wrapper-col'>
                <p className='aboutus-body-section-wrapper-col-title'>
                  We provide <span>long-lasting value</span>
                </p>
                <p
                  className='aboutus-body-section-wrapper-col-description'
                  dangerouslySetInnerHTML={{
                    __html: aboutUsBodyDescriptionThree,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Swiper color={'#fff'} />
      </div>
    </>
  );
};

export default AboutUsBodyComponent;
